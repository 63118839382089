<template>
  <!-- //页面交互部分 -->
  <div class="homeInfo">
    <div class="itemName" v-if="corFlag">北京科技大学工程实践基地二期</div>
    <div class="maskLoading" v-if="corFlag">
      <!-- 视频模型都加载完 -->
      <div class="loadingBtn" v-if="videoLoading">
        <div class="interSys" @click="enterSystem">进入系统</div>
        <!-- <img style="width:200px;cursor: pointer;margin-left:0;" src="../assets/images/login.gif" alt="" > -->
      </div>
      <div class="loading" v-if="!videoLoading">
        <img
          class="loadImg"
          style="width: 46px"
          src="../assets/images/loading.svg"
          alt=""
        />
        <!-- <div class="press">资源加载中请等待···</div> -->
      </div>
    </div>
    <div class="mediaContainer" v-show="!endVideoflag">
      <video
        class="videoBox"
        id="videoBox"
        preload="auto"
        :controls="false"
        src="../assets/video/us.mp4"
      ></video>
      <div @click="intoModelPage" class="backIndex backIndex1">
        <img src="../assets/images/home.svg" alt="" />
        <div class="backHomeBtn">返回主页</div>
      </div>
    </div>
    <div class="mediaContainer" v-show="corFlagPress">
      <video
        class="videoBox"
        id="videoBoxWork"
        preload="auto"
        :controls="false"
        src="../assets/video/work.mp4"
      ></video>
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    return {
      isLoading: false,
      corFlag: true,
      videoLoading: false,
      endVideoflag: false,
      corFlagPress: false,
    };
  },
  methods: {
    //页面交互部分
    enterSystem() {
      const elVideo = document.getElementById("videoBox");
      elVideo.play();
      this.corFlag = false;
      this.otherPageInto = false;
      elVideo.addEventListener(
        "ended",
        function () {
          //结束
          //视频隐藏
          let backBtn = document.getElementsByClassName("backIndex1")[0];
          backBtn.click();
        },
        false
      );
    },
    intoModelPage() {
      this.$router.push({ name: "Building" });
    },
  },
  mounted() {
    let timer = setInterval(() => {
      //监听视频是否加载完成
      const elVideo = document.getElementById("videoBox").readyState;
      if (elVideo == 4) {
        this.videoLoading = true;
        clearInterval(timer);
      }
    }, 10);
  },
};
</script>
<style lang="less" scoped>
//页面交互
.homeInfo {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 20000;
}
.itemName {
  position: fixed;
  top: 30px;
  left: 0;
  // color: #0076a3;
	color:#fff;
  z-index: 2000000;
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}
.maskLoading {
  background: url("../assets/effectImages/sysBg.jpg");
  background-size: cover;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1111111;
  color: #fff;
}

.maskLoading .loading {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  .press {
    color: #666;
    width: 200px;
    float: left;
    font-size: 14px;
  }
}

.loadingBtn {
}

.interSys {
  width: 146px;
  cursor: pointer;
  background: #0076a3;
  text-align: center;
  font-size: 20px;
  color: #fff;
  height: 30px;
  border-radius: 20px;
  border: 1px solid #0076a3;
  box-shadow: 0px 0px 22px -1px rgba(0, 0, 0, 0.3);
}
.loadImg {
  width: 50px;
  height: 50px;
  /*将loading改为行内元素，防止旋转的时候，100%宽度都在旋转*/
  display: inline-block;
  /*设置边框大小，将颜色设置为浅白色*/
  // border: 3px solid #f3f3f3;
  // /*将上边框颜色设置为红色，旋转的时候可以看到旋转的效果*/
  // border-top: 3px solid red;
  /*将内容变成圆形*/
  border-radius: 50%;
}

@keyframes drehen {
  0% {
    /*动画起始为0*/
    transform: rotate(0deg);
  }
  100% {
    /*旋转了360度*/
    transform: rotate(360deg);
  }
}

.loadImg {
  /*给之前的CSS加上动画效果 添加旋转动画 无限旋转*/
  animation: drehen 0.8s infinite linear;
}

.mediaContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  z-index: 19888;
  display: block;
}
.backIndex {
  position: absolute;
  top: 100px;
  right: 100px;
  height: 36px;
  width: 120px;
  /* vertical-align: middle; */
  border-radius: 4px;
  padding-top: 4px;
  box-sizing: border-box;
  padding-left: 10px;
  cursor: pointer;
  background-image: url("../assets/images/infoBg.png");
  color: #fff;
  img {
    width: 24px;
    vertical-align: middle;
  }
}
.loadingBtn {
  position: absolute;
  bottom: 100px;
  right: 140px;
}
.backHomeBtn {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
}
.videoBox {
  width: 100%;
  height: 100%;
  background: #000;
  object-fit: fill;
}
/*video默认全屏按钮*/
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
:-webkit-full-screen video {
  width: 100%;

  height: 100%;
}

:-moz-full-screen video {
  width: 100%;

  height: 100%;
}
.hiddenBox {
  display: none !important;
}
</style>